<template>
  <div class="toastification">
    <div class="d-flex align-items-start">
      <b-avatar
        :variant="variant"
        size="1.8rem"
        class="mr-75 flex-shrink-0"
      >
        <feather-icon
          :icon="icon"
          size="15"
        />
      </b-avatar>
      <div class="w-100">
        <div class="d-flex flex-row">
          <h5
            v-if="title"
            class="mb-0 font-weight-bolder toastification-title"
            :class="`text-${variant}`"
            v-text="title"
          />
          <span
            class="cursor-pointer toastification-close-icon ml-auto mt-0"
            @click="$emit('close-toast')"
          >
            <feather-icon
              v-if="!hideClose"
              icon="XIcon"
              class="text-body"
            />
          </span>
        </div>
        <div class="mt-1 d-flex flex-row align-items-center">
          <small
            v-if="text"
            class="d-inline-block text-body"
            v-text="text"
          />
          <div class="w-full text-right" v-if="showButton">
            <b-button :variant="`outline-${variant}`" @click="$emit('clickButton')">{{textButton}}</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar, BButton } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BButton
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    showButton: {
      type: Boolean,
      default: false
    },
    textButton: {
      type: String,
      default: ''
    }
  },
}
</script>

<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}

.toastification-title {
  color: inherit;
}
.icon-close {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
