<template>
  <div>
    <quill-editor
    id="quil-content"
    v-model="text"
    :options="editorOption"
    class="border-bottom-0"
    style="max-width: 100%; height: 150px;"
    @blur="changeText()"
    />
    <div
    :id="`quill-toolbar-${label}`"
    class="d-flex justify-content-end border-top-0"
    >
    <button class="ql-bold" />
    <button class="ql-italic" />
    <button class="ql-underline" />
    <button class="ql-align" />
    <button class="ql-link" />
    </div>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    prev_text: {
      type: String,
      default: ''
    }
  },
  components: {
    quillEditor
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: `#quill-toolbar-${this.label}`
        },
        placeholder: this.$t('campaigns.writeHere')
      },
      text: ''
    }
  },
  created() {
    this.text = this.prev_text
  },
  methods: {
    changeText() {
      this.$emit('changeText', this.text)
    }
  }
}
</script>